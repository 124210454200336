import Matomo from '@components/Matomo';
import React from 'react';

function CustomScripts() {
  return (
    <>
      <Matomo />
      <script
        async
        defer
        src='https://stable.loyjoy.com/widget.js?process=64f3ec7a-20cf-4c61-b21e-f9cf5b2e6507'
      />
    </>
  );
}

export default CustomScripts;
